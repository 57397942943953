import { useEffect, useState } from 'react';
import styles from './FixcenterOrderingTable.module.scss';
import { useParams } from 'react-router-dom';
import { getFixcenterProductByCategoryId } from '../../services/products.service';
import { FixcenterProduct } from '../../shared/models/fixcenter-product.model';
import { determineIfRedirectOrDownload, getAttachmentsByProductId } from '../../shared/services/attachments.service';
import { AttachmentFile } from '../../shared/models/attachment-file.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { pdfRedirector } from '../../shared/services/attachments.service';
import OrderingTableFilters from './OrderingTableFilters/OrderingTableFilters';

interface QuantityState {
    [productId: string]: number;
}

export default function FixcenterOrderingTable(props: any) {
    const { productId } = useParams();
    const [products, setProducts] = useState<FixcenterProduct[]>([]);
    const [quantities, setQuantities] = useState<QuantityState>({});
    const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
    const [hasProductsCame, setHasProductsCame] = useState<boolean>(false);

    const fetchProducts = async (productId: string) => {
        try {
            const response = await getFixcenterProductByCategoryId(productId);
            if (response.data) {
                setProducts(response.data as FixcenterProduct[]);
                setQuantities(Object.fromEntries(response.data.map((product: FixcenterProduct) => [product.id, 1])));
                props.handleSetProducts(response.data as FixcenterProduct[]);
            }
        } catch (error) {
            console.error("Błąd podczas pobierania produktu:", error);
        }
        setHasProductsCame(true);
    };

    const fetchAttachments = async () => {
        const updatedProducts = await Promise.all(products.map(async (product) => {
            if (product.id && product.attachmentsId !== null) {
                const response = await getAttachmentsByProductId(product.id);
                product.attachments = response.data as AttachmentFile[];
            }
            return product;
        }));
        setProducts(updatedProducts);
    };

    useEffect(() => {
        if (productId) {
            fetchProducts(productId);
        }
    }, [productId]);

    useEffect(() => {
        if (hasProductsCame) {
            fetchAttachments();
        }
    }, [hasProductsCame])



    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>, productId: string) => {
        const newQuantity = parseInt(event.target.value) || 1;
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: newQuantity
        }));
    };


    const toggleRowExpansion = (productId: string) => {
        setExpandedRows(prevState => ({
            ...prevState,
            [productId]: !prevState[productId]
        }));
    };

    return (
        <div className={styles.container}>
            <p>ALL PRICES ARE WITHOUT VAT</p>
            <p>PLEASE REMEMBER THAT PACKAGING SHOWS NUMBER OF ELEMENTS IN BOX, QUANTITY IS NUMBERS OF ITEMS, NOT NUMBER OF BOXES.</p>
            {productId && <OrderingTableFilters productId={productId}></OrderingTableFilters>}
            <div className={styles.table_container}>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <span>VARIANT NAME</span>
                    </div>
                    <div className={styles.column}>
                        <span>CODE</span>
                    </div>
                    <div className={styles.column}>
                        <span>DOCUMENTS</span>
                    </div>
                    <div className={styles.column}>
                        <span>PACKAGING</span>
                    </div>
                    <div className={styles.column}>
                        <span>MIN QUANTITY</span>
                    </div>
                    <div className={styles.column}>
                        <span>UNIT PRICE</span>
                    </div>
                    <div className={styles.column}>
                        <span>QUANTITY</span>
                    </div>
                    <div className={styles.column}>
                        <span>TOTAL PRICE</span>
                    </div>
                    <div className={styles.column}>
                        <span></span>
                    </div>
                </div>
                {products && products.map((product, index) => (
                    <div key={index}>
                        <div className={`${styles.row} ${index % 2 == 0 ? styles.white_background : styles.grey_background} ${expandedRows[product.id] ? styles.border_none : ''}`}>
                            <div className={styles.column}>
                                <span className={styles.product_value}>{product.name}</span>
                            </div>
                            <div className={styles.column}>
                                <span className={styles.product_value}>{product.code}</span>
                            </div>
                            <div className={styles.column}>
                                {product.attachments && product.attachments?.length > 0 &&
                                    <FontAwesomeIcon className={styles.redIcon} size="2x" onClick={() => { toggleRowExpansion(product.id) }} icon={faFile} />
                                }
                            </div>
                            <div className={styles.column}>
                                <span></span>
                            </div>
                            <div className={styles.column}>
                                <span></span>
                            </div>
                            <div className={styles.column}>
                                <span className={styles.product_value}>{product.productPricings[0]?.brutto.toFixed(2)} €</span>
                            </div>
                            <div className={styles.column}>
                                <input type="number" value={quantities[product.id] || 1} onChange={(event) => handleQuantityChange(event, product.id)} />
                            </div>
                            <div className={styles.column}>
                                {product.productPricings[0]?.brutto &&
                                    <span className={styles.product_value}>{((quantities[product.id] || 1) * product.productPricings[0]?.brutto).toFixed(2)} €</span>
                                }
                            </div>
                            <div className={styles.column}>
                                <button>ADD TO CART</button>
                            </div>
                        </div>
                        {expandedRows[product.id] && product.attachments && product.attachments?.length > 0 && (
                            <div className={`${styles.expandedRow} ${styles.expanded}`}>
                                {product.attachments.map((attachment, index) => (
                                    <div className={styles.pdf_link} onClick={() => { determineIfRedirectOrDownload(attachment) }} key={index}>{attachment.name}</div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
