import { useEffect, useState } from "react";
import FirstLevelProductCard from "../../components/first-level-product-card/FirstLevelProductCard";
import styles from "./ProductsFirstLevel.module.scss";
import axios from "axios";
import { Category } from "../../components/products-page/components/products-categories/models/Category.model";
import { getFixcenterCategories } from "../../services/products.service";
import { ProductFilter } from "../../components/product-filters-menu/models/product-filter.model";
import { FixcenterFilterDto } from "../../components/product-filters-menu/models/fixcenter-filter-dto.model";

export default function ProductsFirstLevel() {

    const [mainCategories, setMainCategories] = useState<Category[]>([]);
    const guidEmpty = "00000000-0000-0000-0000-000000000000";

    const fetchCategories = async () => {

        const productFilter: ProductFilter = {
            isCategory: true,
            parentProductId: guidEmpty,
        }

        const fixcenterFilter: FixcenterFilterDto = {
            productFilter: productFilter,
        }

        try {
            getFixcenterCategories(fixcenterFilter).then((response: any) => {
                if (response) {
                    const categoriesResponse = response.data as Category[];
                    setMainCategories(categoriesResponse);
                }
            })
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {mainCategories.map((category, index) => (
                    <div key={index} className={styles.single_product}>
                        <FirstLevelProductCard category={category} />
                    </div>
                ))}
            </div>
        </div>
    );
}
