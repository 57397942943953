import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './SearchInput.module.scss';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export default function SearchInput() {
    const [value, setValue] = useState<string>("");
    const navigate = useNavigate();

    const handleSearch = () => {
        const query = encodeURIComponent(value);
        navigate(`/webshop/search/${value}`);
    };

    return (
        <div className={styles.container}>
            <input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Product name or code"
            />
            <button
                type="button"
                onClick={handleSearch}
                className={styles.iconButton}
            >
                <FontAwesomeIcon icon={faMagnifyingGlass} size='sm' />
            </button>
        </div>
    );
}
