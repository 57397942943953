import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styles from './LoginMenu.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default function LoginMenu() {
    return (
        <div className={styles.container}>
            <div className={styles.header}>Login Menu</div>
            <Link to="/login" className={styles.menu_row}>
                <FontAwesomeIcon icon={faChevronRight} className={styles.icon} size="xs" />Login
            </Link>
            <Link to="/register" className={styles.menu_row}>
                <FontAwesomeIcon icon={faChevronRight} className={styles.icon} size="xs" />Register
            </Link>
            <Link to='/reset-password' className={styles.menu_row}>
                <FontAwesomeIcon icon={faChevronRight} className={styles.icon} size="xs" />Restore password
            </Link>
        </div>
    );
}
