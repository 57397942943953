import MyBuildingSitesHeader from './my-building-sites-header/MyBuildingSitesHeader';
import MyBuildingSitesTable from './my-building-sites-table/MyBuildingSitesTable';
import styles from './MyBuildingSites.module.scss';

export default function MyBuildingSites() {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <MyBuildingSitesHeader />
                <MyBuildingSitesTable />
            </div>
        </div>
    )
}