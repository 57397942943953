import axios from "axios";
import { AttachmentFile } from "../models/attachment-file.model";

export const getAttachmentsByProductId = (productId: string) => {
    return axios.get(`attachments/getAttachmentsByProductId/${productId}`);
}

export const pdfRedirector = (content: string) => {
    if (content) {
        const pdfData = atob(content);
        const byteArray = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
            byteArray[i] = pdfData.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url);
    }
}

export const determineIfRedirectOrDownload = (attachment: AttachmentFile) => {
    if (attachment.name.endsWith('pdf')) {
        pdfRedirector(attachment.content);
    }
    else {
        downloadFile(attachment.content, attachment.name);
    }
}


const downloadFile = (content: string, fileName: string) => {
    const byteArray = base64ToUint8Array(content);
    const blob = new Blob([byteArray]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


const base64ToUint8Array = (base64: string): Uint8Array => {
    const binaryString = atob(base64);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
    }
    return byteArray;
}
