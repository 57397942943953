import { useParams } from 'react-router-dom';
import Header from '../../shared/header/Header';
import styles from './OrderEmailConfirmation.module.scss';
import { useEffect } from 'react';
import { confirmOrder } from './post-confirm-order.service';
import { useTranslation } from 'react-i18next';
import { usePluginStore } from 'react-pluggable';

export default function OrderEmailConfirmation() {
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");
    const currentUrl = window.location.href;
    const { t, i18n } = useTranslation('translation');
    const urlParts = currentUrl.split('/');
    const orderId = urlParts[urlParts.length - 1];

    useEffect(() => {
        confirmOrder(orderId)
    }, []);


    return (

        <div className={styles.main_container}>
            <div className={styles.header}>
                <Header />
            </div>
            <div className={styles.content}>
                <h1 className={styles.confirmation_text}>
                    <p>{t("emailConfirmation.order.confirmed")}</p>
                </h1>
            </div>
            <div className={styles.footer}>
                <Renderer placement="page:fixcenterFooter" />
            </div>
        </div>
    );
}

