import styles from './MyInvoicesTable.module.scss';

export default function MyInvoicesTable() {

    return (
        <div className={styles.table_container}>
            <div className={`${styles.row} ${styles.no_hover}`}>
                <div className={`${styles.column}  ${styles.bold}`}>ORDER NUMBER</div>
                <div className={`${styles.column}  ${styles.bold}`}>INVOICE NUMBER</div>
                <div className={`${styles.column}  ${styles.bold}`}>DATE</div>
                <div className={`${styles.column}  ${styles.bold}`}>AMOUNT</div>
                <div className={`${styles.column}  ${styles.bold}`}>IS PAYED</div>
            </div>
            <div className={`${styles.row}`}>
                <div className={`${styles.column}`}>20425</div>
                <div className={`${styles.column}`}>658929</div>
                <div className={`${styles.column}`}>19/06/2021 00:00:00</div>
                <div className={`${styles.column}`}>203.30</div>
                <div className={`${styles.column}`}>True</div>
            </div>
            <div className={`${styles.row} ${styles.odd_row}`}>
                <div className={`${styles.column}`}>20425</div>
                <div className={`${styles.column}`}>658929</div>
                <div className={`${styles.column}`}>19/06/2021 00:00:00</div>
                <div className={`${styles.column}`}>203.30</div>
                <div className={`${styles.column}`}>True</div>
            </div>
        </div >
    )
}