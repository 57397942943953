import FixcenterNavigation from '../../components/fixcenter-components/fixcenter-navigation/FixcenterNavigation';
import CartBasketTable from './cart-basket-table/CartBasketTable';
import CartHeader from './cart-header/CartHeader';
import CartOrderForm from './cart-order-form/CartOrderForm';
import styles from './ShoppingCartContainer.module.scss';

export default function ShoppingCartContainer() {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <CartHeader />
                <CartOrderForm />
                <CartBasketTable />
            </div>
        </div>
    );
}