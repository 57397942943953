import React, { useEffect, useState } from 'react';
import styles from './DropdownList.module.scss';

interface DropdownListProps {
    values?: string[];
    name: string;
    featureId: string;
    onEnumValuesChange: (featureId: string, enumValues: string[] | boolean) => void;
    isBoolean: boolean;
    clear?: number;
    isInitiallyOpen: boolean
}

const DropdownList = ({ values, name, featureId, onEnumValuesChange, isBoolean, clear, isInitiallyOpen }: DropdownListProps) => {
    const [isOpen, setIsOpen] = useState(isInitiallyOpen);
    const [enumValues, setEnumValues] = useState<string[]>([]);
    const [booleanValue, setBooleanValue] = useState<boolean>(false);

    useEffect(() => {
        setEnumValues([]);
        setBooleanValue(false);
        onEnumValuesChange(featureId, isBoolean ? false : []);
    }, [clear]);

    const handleDropdownClick = () => {
        setIsOpen(!isOpen);
    };

    const handleEnumValuesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        const updatedEnumValues = checked
            ? [...enumValues, value]
            : enumValues.filter(v => v !== value);

        setEnumValues(updatedEnumValues);
        onEnumValuesChange(featureId, updatedEnumValues);
    };

    const handleBooleanValuesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setBooleanValue(checked);
        onEnumValuesChange(featureId, checked);
    };

    return (
        <div className={styles.dropdownContainer}>
            <div
                className={`${styles.dropdownButton} ${isOpen ? styles.dropdownButtonOpen : ''}`}
                onClick={handleDropdownClick}
            >
                {name}
            </div>
            <div className={`${styles.dropdownContent} ${isOpen ? styles.dropdownContentOpen : ''}`}>
                {!isBoolean && values && values.map((item, index) => (
                    <div className={styles.dropdownRow} key={index}>
                        <div className={styles.checkboxContainer}>
                            <input
                                type="checkbox"
                                value={item}
                                onChange={handleEnumValuesChange}
                                checked={enumValues.includes(item)}
                            />
                        </div>
                        <div className={styles.labelContainer}>
                            <label>
                                {item}
                            </label>
                        </div>
                    </div>
                ))}
                {isBoolean &&
                    <div className={styles.dropdownRow}>
                        <div className={styles.checkboxContainer}>
                            <input
                                type="checkbox"
                                onChange={handleBooleanValuesChange}
                                checked={booleanValue}
                            />
                        </div>
                        <div className={styles.labelContainer}>
                            <label>
                                yes
                            </label>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default DropdownList;
