import React, { useCallback, useEffect, useState, useRef } from 'react';
import styles from './FilteringSlider.module.scss';

interface FilteringSliderProps {
    min: number;
    max: number;
    onChange: (range: { min: number; max: number }) => void;
    name: string;
    featureId: string;
    onRangeChange: (featureId: string, rangeFrom: string, rangeTo: string) => void;
    clear?: number;
    isInitiallyOpen: boolean
}

const FilteringSlider: React.FC<FilteringSliderProps> = ({ min, max, onChange, name, featureId, onRangeChange, clear, isInitiallyOpen }) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const minValRef = useRef(min);
    const maxValRef = useRef(max);
    const range = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(isInitiallyOpen);
    const minCopy = min;
    const maxCopy = max;

    const getPercent = useCallback(
        (value: number) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );


    useEffect(() => {
        if (clear !== undefined && clear > 0) {
            setMinVal(minCopy);
            setMaxVal(maxCopy);
        }
    }, [clear])



    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxValRef.current);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, getPercent]);

    useEffect(() => {
        const minPercent = getPercent(minValRef.current);
        const maxPercent = getPercent(maxVal);

        if (range.current) {
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [maxVal, getPercent]);

    useEffect(() => {
        onChange({ min: minVal, max: maxVal });
        onRangeChange(featureId, minVal.toString(), maxVal.toString());
    }, [minVal, maxVal]);

    const minValueStyle = {
        left: `${getPercent(minVal)}%`,
        transform: 'translateX(-50%)'
    };

    const maxValueStyle = {
        left: `${getPercent(maxVal)}%`,
        transform: 'translateX(-50%)'
    };

    const handleDropdownClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.dropdown_container}>
            <div
                className={`${styles.dropdownButton} ${isOpen ? styles.dropdownButtonOpen : ''}`}
                onClick={handleDropdownClick}
            >
                {name}
                <span className={`${styles.dropdownIcon} ${isOpen ? styles.dropdownIconOpen : ''}`} />
            </div>
            {isOpen && (
                <div className={styles.slider}>
                    <div className={styles.slider_values}>
                        <div className={styles.value} style={minValueStyle}>{minVal}</div>
                        <div className={styles.value} style={maxValueStyle}>{maxVal}</div>
                    </div>
                    <input
                        type="range"
                        min={min}
                        max={max}
                        value={minVal}
                        onChange={(event) => {
                            const value = Math.min(Number(event.target.value), maxVal - 1);
                            setMinVal(value);
                            minValRef.current = value;
                        }}
                        className={`${styles.thumb} ${styles.thumb_left}`}
                        style={{ zIndex: minVal > max - 100 ? '5' : 'auto' } as React.CSSProperties}
                    />
                    <input
                        type="range"
                        min={min}
                        max={max}
                        value={maxVal}
                        onChange={(event) => {
                            const value = Math.max(Number(event.target.value), minVal + 1);
                            setMaxVal(value);
                            maxValRef.current = value;
                        }}
                        className={`${styles.thumb} ${styles.thumb_right}`}
                    />
                    <div className={styles.slider_track} />
                    <div ref={range} className={styles.slider_range} />
                </div>
            )}
        </div>
    );
};

export default FilteringSlider;
