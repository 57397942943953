import { useEffect, useState } from 'react';
import styles from './SelectWithCheckboxes.module.scss';
import { isSetIterator } from 'util/types';

interface SelectWithCheckboxesProps {
    name: string;
    clear?: number;
    isInitiallyOpen: boolean
}

export default function SelectWithCheckboxes({ name, clear, isInitiallyOpen }: SelectWithCheckboxesProps) {

    const [isOpen, setIsOpen] = useState(isInitiallyOpen);
    const [areCheckboxesVisible, setAreCheckboxesVisible] = useState(false);

    const handleDropdownClick = () => {
        if (areCheckboxesVisible && isOpen) {
            setAreCheckboxesVisible(false);
        }
        setIsOpen(!isOpen);

    };

    const handleShowCheckboxes = () => {
        setAreCheckboxesVisible(!areCheckboxesVisible)
    }
    return (
        <div className={styles.dropdownContainer}>
            <div
                className={`${styles.dropdownButton} ${isOpen ? styles.dropdownButtonOpen : ''}`}
                onClick={handleDropdownClick}
            >
                {name}
            </div>
            {isOpen &&
                <div onClick={handleShowCheckboxes} className={`${styles.dropdownContent} ${isOpen ? styles.dropdownContentOpen : ''}`}>
                </div>
            }
            {
                areCheckboxesVisible &&
                <div className={`${styles.checkboxesDropdownContent} ${isOpen ? styles.dropdownContentOpen : ''}`}>
                </div>
            }
        </div>
    )
}