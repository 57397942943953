// slices/breadcrumbsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Breadcrumb {
    label: string;
    link: string;
}

interface BreadcrumbsState {
    breadcrumbs: Breadcrumb[];
}

const breadcrumbsInitialState: BreadcrumbsState = {
    breadcrumbs: []
};

const breadcrumbsSlice = createSlice({
    name: 'breadcrumbs',
    initialState: breadcrumbsInitialState,
    reducers: {
        setBreadcrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {
            state.breadcrumbs = action.payload;
        },
        clearBreadcrumbs: (state) => {
            state.breadcrumbs = [];
        }
    }
});

export const { setBreadcrumbs, clearBreadcrumbs } = breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
