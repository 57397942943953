import FixcenterNavigation from "../../../components/fixcenter-components/fixcenter-navigation/FixcenterNavigation";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import { MY_OFFERS_PATHS } from "../../../shared/constants/breadcrumb.constants";
import styles from './MyOffersHeader.module.scss';

export default function MyOffersHeader() {
    useBreadcrumbs(MY_OFFERS_PATHS);
    return (
        <div className={styles.container}>
            <FixcenterNavigation />
            <div className={styles.row}>
                <div className={styles.header}>
                    <p className={styles.title}>OFFERS</p>
                    <div className={styles.input_row}>
                        Search
                        <input />
                    </div>
                </div>
            </div>
        </div>
    )
}