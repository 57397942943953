import { Category } from '../products-page/components/products-categories/models/Category.model';
import styles from './FirstLevelProductCard.module.scss';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { decodeImageService, getProductImagesService } from '../../shared/services';
import { ProductImage } from '../../shared/product-card/models/product-image.model';
import * as authService from '../../auth/services/auth.service';
import { EMPTY_GUID, FIRST_LEVEL_PRODUCT_CARD_HEIGHT, FIRST_LEVEL_PRODUCT_CARD_WIDTH } from '../../shared/constants/images-resolution.constants';
import { getDimensionedImage } from '../../shared/services/get-attachments.service';
const noImage = require('../../assets/img/Fixcenter-noimage.png')

interface Props {
    category: Category;
    onImageSelect?: any;
}

export default function FirstLevelProductCard({ category, onImageSelect }: Props) {

    const [categoryImage, setCategoryImage] = useState<string>();

    const checkIfIsLoggedIn = (): boolean => {
        const isLoggedIn: boolean = authService.checkLogInStatus();
        return isLoggedIn
    }

    const fetchCategoryImages = (category: Category) => {
        if (category.imageIds[0] !== null && category.imageIds[0] !== undefined && category.imageIds[0] !== EMPTY_GUID) {
            getDimensionedImage(category.imageIds[0], FIRST_LEVEL_PRODUCT_CARD_WIDTH, FIRST_LEVEL_PRODUCT_CARD_HEIGHT).then((response) => {
                if (response) {
                    setCategoryImage(response)
                }
            })
        }
    }


    useEffect(() => {
        if (category.id !== null) {
            fetchCategoryImages(category);
        }
    }, [category])

    const handleImageSelect = (src: string | undefined): void => {
        if (onImageSelect !== undefined) {
            onImageSelect(src);
        }
    };

    return (
        <div className={styles.container} onMouseEnter={() => handleImageSelect(categoryImage)}>
            <div className={styles.product_image}>
                <Link to={checkIfIsLoggedIn() ? `/webshop/category/${category.name}/${category.id}` : '/login'} className={styles.image_container}>
                    <img src={categoryImage ? categoryImage : noImage} alt={"Product"} />
                </Link>

            </div>
            <div className={styles.product_name}>
                <Link to={checkIfIsLoggedIn() ? `/webshop/category/${category.name}/${category.id}` : '/login'} className={styles.link}>{category.name}</Link>
            </div>
        </div>
    );
}
