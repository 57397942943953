import { IPlugin, PluginStore } from "react-pluggable";
import FixcenterProductsPage from "./components/FixcenterProductsPage/FixcenterProductsPage";

class FixcenterProductsPagePlugin implements IPlugin {
    pluginStore!: PluginStore;
    getPluginName(): string {
        return "FixcenterProductsPage@1.0.0"
    }

    getDependencies(): string[] {
        return []
    }
    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }
    activate(): void {
        this.pluginStore.executeFunction('Renderer.add', 'page:fixcenterSlider', () => (
            <FixcenterProductsPage />
        ));
    }

    deactivate(): void {
    }
}

export default FixcenterProductsPagePlugin