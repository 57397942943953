import styles from './MyOrdersTable.module.scss';


export default function MyOrdersTable() {

    return (
        <div className={styles.table_container}>
            <div className={styles.row}>
                <div className={`${styles.column} ${styles.number_column} ${styles.bold}`}>NUMBER</div>
                <div className={`${styles.column} ${styles.building_site_column} ${styles.bold}`}>BUILDING SITE</div>
                <div className={`${styles.column} ${styles.status_column} ${styles.bold}`}>STATUSCODE</div>
                <div className={`${styles.column} ${styles.date_column} ${styles.bold}`}>CREATION DATE</div>
                <div className={`${styles.column} ${styles.user_column} ${styles.bold}`}>USER</div>
                <div className={`${styles.column} ${styles.buttons_column} ${styles.bold}`}></div>
            </div>
            <div className={styles.row}>
                <div className={`${styles.column} ${styles.number_column}`}>16618</div>
                <div className={`${styles.column} ${styles.building_site_column}`}>Lwowska lub Katowicka, Poznan, 61-131, Poland</div>
                <div className={`${styles.column} ${styles.status_column}`}>In progress</div>
                <div className={`${styles.column} ${styles.date_column}`}>06/11/2023 12:11:05</div>
                <div className={`${styles.column} ${styles.user_column}`}>mateusz.zielinski@idealogic.pl</div>
                <div className={`${styles.column} ${styles.buttons_column}`}>
                    <button>Details</button>
                    <button>Excel</button>
                    <button>Pdf</button>
                </div>
            </div>
            <div className={styles.row}>
                <div className={`${styles.column} ${styles.number_column}`}>16618</div>
                <div className={`${styles.column} ${styles.building_site_column}`}>Lwowska lub Katowicka, Poznan, 61-131, Poland</div>
                <div className={`${styles.column} ${styles.status_column}`}>In progress</div>
                <div className={`${styles.column} ${styles.date_column}`}>06/11/2023 12:11:05</div>
                <div className={`${styles.column} ${styles.user_column}`}>mateusz.zielinski@idealogic.pl</div>
                <div className={`${styles.column} ${styles.buttons_column}`}>
                    <button>Details</button>
                    <button>Excel</button>
                    <button>Pdf</button>
                </div>
            </div>
        </div >
    )
}