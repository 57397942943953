import { t } from "i18next";
import styles from "./RegisterNow.module.scss";


export default function RegisterNowComponent() {
    return (
        <div className={styles.register_now}>
            <a href={"register"}>{t("loginForm.registerNow")}</a>
        </div>
    )
}
