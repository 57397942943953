import FixcenterNavigation from '../../../components/fixcenter-components/fixcenter-navigation/FixcenterNavigation';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { MY_BUILDING_SITES_PATHS } from '../../../shared/constants/breadcrumb.constants';
import CartButtons from '../../shopping-cart-container/cart-buttons/CartButtons';
import styles from './MyBuildingSitesHeader.module.scss';

export default function MyBuildingSitesHeader() {
    useBreadcrumbs(MY_BUILDING_SITES_PATHS);
    return (
        <div className={styles.container}>
            <FixcenterNavigation />
            <div className={styles.row}>
                <div className={styles.header}>
                    <p className={styles.notification}>BUILDING SITES</p>
                </div>
                <div className={styles.buttons_container}>
                    <CartButtons version={2}></CartButtons>
                </div>
            </div>
        </div>
    )
}