import styles from './AddBuildingSiteForm.module.scss';

export default function AddBuildingSiteForm() {

    return (
        <div className={styles.container}>

            <div className={styles.content}>
                <h4>Add new building site</h4>
                <form className={styles.form}>
                    <div className={styles.row}>

                        <div className={styles.label}>Name*</div>
                        <input />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Code*</div>
                        <input />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Street*</div>
                        <input />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Country*</div>
                        <select>
                            <option>AV PRINCE BAUDOUIN 3</option>
                        </select>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.label}>Zip*</div>
                        <input />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>City*</div>
                        <input />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Fax</div>
                        <input />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Phone*</div>
                        <input />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Email</div>
                        <input />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Contact</div>
                        <input />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Reference</div>
                        <input />
                    </div>
                    <div className={`${styles.row} ${styles.button_row}`}>
                        <button>Create</button>
                    </div>
                </form>
            </div>
        </div >
    )
}
