import React from 'react';
import styles from './RotatingRing.module.scss'

const RotatingRing = () => {
    return (
        <div className={styles.ring_container}>
            <div className={styles.ring}></div>
        </div>
    );
};

export default RotatingRing;
