import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store'; // Upewnij się, że ścieżka jest poprawna
import styles from './FixcenterNavigation.module.scss';
import { Breadcrumb } from '../../../store/reducers'; // Importuj interfejs Breadcrumb

const FixcenterNavigation: React.FC = () => {
    const breadcrumbs = useSelector((state: RootState) => state.breadcrumbs.breadcrumbs);

    return (
        <nav className={styles.breadcrumbs}>
            {breadcrumbs.map((breadcrumb: Breadcrumb, index: number) => (
                <div
                    key={index}
                    className={styles.linkWrapper}
                    style={{ '--z-index': breadcrumbs.length - index } as React.CSSProperties}
                >
                    {breadcrumb.link ? (
                        <Link
                            to={breadcrumb.link}
                            className={`${styles.breadcrumbs__item} ${index === breadcrumbs.length - 1 ? styles.active : ''}`}
                        >
                            {breadcrumb.label}
                        </Link>
                    ) : (
                        <span className={`${styles.breadcrumbs__item} ${index === breadcrumbs.length - 1 ? styles.active : ''}`}>
                            {breadcrumb.label}
                        </span>
                    )}
                </div>
            ))}
        </nav>
    );
};

export default FixcenterNavigation;
