import { useState } from 'react';
import styles from './FixcenterFooterBanner.module.scss';

export default function FixcenterFooterBanner() {
    const newArrivals = require('../../assets/img/FixcenterNewArrivals.png');
    const askForAccess = require('../../assets/img/FixcenterAskForAccess.png');
    const supportMachines = require('../../assets/img/FixcenterSupportMachines.png');
    const webshop = require('../../assets/img/FixcenterWebShop.png');

    const [bgImage, setBgImage] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [resetAnimation, setResetAnimation] = useState(false);

    const handleMouseEnter = () => {
        setResetAnimation(true);
        setIsActive(true);
        setBgImage(newArrivals);

        // Set a timer to reset the animation state after the transition duration
        setTimeout(() => {
            setResetAnimation(false);
        }, 1200); // Match the transition duration in CSS
    };

    return (
        <div
            className={`${styles.container} ${isActive ? styles.isActive : ''} ${resetAnimation ? styles.reset_animation : ''}`}
            onMouseEnter={handleMouseEnter}
        >
            <div
                className={styles.left_side}
                style={{ backgroundImage: `url(${bgImage})` }}
            >
            </div>
            <div className={styles.right_side}>
                <div className={styles.text_wrapper}>
                    <div
                        className={`${styles.red_background} ${styles.margin_80}`}
                        onMouseEnter={() => setBgImage(newArrivals)}
                    >
                        NEW ARRIVALS
                    </div>
                    <div
                        className={`${styles.dark_background} ${styles.margin_60}`}
                        onMouseEnter={() => setBgImage(webshop)}
                    >
                        WEB SHOP
                    </div>
                    <div
                        className={`${styles.red_background} ${styles.margin_40}`}
                        onMouseEnter={() => setBgImage(askForAccess)}
                    >
                        ASK FOR ACCESS
                    </div>
                    <div
                        className={`${styles.dark_background} ${styles.margin_bottom}`}
                        onMouseEnter={() => setBgImage(supportMachines)}
                    >
                        WE SUPPORT YOUR MACHINES TOO
                    </div>
                </div>
            </div>
        </div>
    );
}
