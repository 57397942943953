import { useParams } from "react-router-dom";
import styles from "./ProductsSecondLevel.module.scss";
import { Category } from "../../components/products-page/components/products-categories/models/Category.model";
import { useEffect, useState } from "react";
import SecondLevelProductCard from "../../components/second-level-product-card/SecondLevelProductCard";
import ProductFiltersMenu from "../../components/product-filters-menu/ProductFiltersMenu";
import { getFixcenterCategories } from "../../services/products.service";
import { FixcenterFilterDto } from "../../components/product-filters-menu/models/fixcenter-filter-dto.model";
import { ProductFilter } from "../../components/product-filters-menu/models/product-filter.model";
import RotatingRing from "../../components/rotating-ring-animation/RotatingRing";

export default function ProductsSecondLevel() {
    const { categoryId, categoryName } = useParams();

    const [products, setProducts] = useState<Category[]>([]);
    const [searchByName, setSearchByName] = useState<string>("");
    const [isAnimationVisible, setIsAnimationVisible] = useState<boolean>(false);

    const handleCategoryNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchByName(event.target.value);
    };

    const fetchCategories = async (fixcenterFilter: FixcenterFilterDto) => {
        try {
            if (categoryId) {
                setIsAnimationVisible(true);
                getFixcenterCategories(fixcenterFilter).then((response: any) => {
                    if (response) {
                        const categoriesResponse = response.data as Category[];
                        setProducts(categoriesResponse);
                        setIsAnimationVisible(false);
                    }
                })
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (categoryId) {
            const languageCode = localStorage.getItem("lang");
            if (languageCode) {
                const productFilter: ProductFilter = {
                    isCategory: true,
                    parentProductId: categoryId,
                }

                const fixcenterFilter: FixcenterFilterDto = {
                    productFilter: productFilter,
                    languageCode: languageCode
                }
                fetchCategories(fixcenterFilter);
            }
        }
    }, [categoryId]);


    return (
        <div className={styles.container}>
            {isAnimationVisible && <RotatingRing />}
            <div className={styles.filters_menu}>
                <ProductFiltersMenu categoryName={categoryName} fetchCategories={fetchCategories} searchByName={handleCategoryNameChange} />
            </div>
            <div className={styles.content}>
                <div className={styles.category_header}>
                    {products.length > 0 ?
                        <p>{categoryName}</p>
                        :
                        <p className={styles.notification}>Notification<br></br>
                            Filtering conditions matches 0 items</p>}
                </div>
                {categoryName &&
                    <div className={styles.cards_container}>
                        {products.map((product, index) => (
                            <div key={index} className={styles.single_product}>
                                <SecondLevelProductCard categoryName={categoryName} product={product} />
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}