import { useEffect, useState } from 'react';
import FixcenterNavigation from '../../../components/fixcenter-components/fixcenter-navigation/FixcenterNavigation';
import styles from './MyOrdersHeader.module.scss';
import { useLocation } from 'react-router-dom';
import { MY_BACK_ORDERS_PATHS, MY_ORDERS_PATHS } from '../../../shared/constants/breadcrumb.constants';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';

export default function MyOrdersHeader() {

    const location = useLocation();
    const [isBackOrdersSite, setIsBackOrdersSite] = useState<boolean>();
    const [title, setTitle] = useState<string>('');

    const paths = location.pathname.includes("BackOrder") ? MY_BACK_ORDERS_PATHS : MY_ORDERS_PATHS;
    useBreadcrumbs(paths);

    useEffect(() => {
        const isBackOrder = location.pathname.includes("BackOrder");
        setIsBackOrdersSite(isBackOrder);
        setTitle(isBackOrder ? 'My back orders' : 'My orders');
    }, [location.pathname]);

    return (
        <div className={styles.container}>
            <FixcenterNavigation />
            <div className={styles.row}>
                <div className={styles.header}>
                    <p className={styles.title}>{title.toUpperCase()}</p>
                    <p>
                        You can sort Your orders using different criteria. To do that, just click criteria You want to order by, for example reference number, building site or creation date.
                    </p>
                </div>
            </div>
        </div>
    )
}