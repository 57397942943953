export const SERVICES: string = "SERVICES";
export const COMPANY: string = "COMPANY";
export const CAREER: string = "CAREER";
export const CONTACT_US: string = "CONTACT_US";
export const ENGINEERING: string = "ENGINEERING";
export const VISIT_US: string = "VISIT_US";
export const ABOUT: string = "ABOUT";
export const CONTACT: string = "CONTACT";
export const HOW_TO_BUY: string = "HOW_TO_BUY";
export const PRIVACY_POLICY: string = "PRIVACY_POLICY"
