import FixcenterNavigation from '../../../components/fixcenter-components/fixcenter-navigation/FixcenterNavigation';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { MY_INVOICES_PATHS } from '../../../shared/constants/breadcrumb.constants';
import styles from './MyInvoicesHeader.module.scss';

export default function MyInvoicesHeader() {
    useBreadcrumbs(MY_INVOICES_PATHS);
    return (
        <div className={styles.container}>
            <FixcenterNavigation />
            <div className={styles.header}>
                <p>INVOICES</p>
            </div>
        </div>
    )
}