import MyOrdersHeader from './my-orders-header/MyOrdersHeader';
import MyOrdersTable from './my-orders-table/MyOrdersTable';
import styles from './MyOrdersContainer.module.scss';

export default function MyOrdersContainer() {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <MyOrdersHeader />
                <MyOrdersTable />
            </div>
        </div>
    )
}