import { Link } from 'react-router-dom';
import styles from './LoginOrRegisterFixcenter.module.scss';

export default function LoginOrRegisterFixcenter() {

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.title}>
                    LOGIN OR CREATE ACCOUNT FOR ONLINE WEBSHOP
                </div>
                <div className={styles.btns_container}>
                    <Link className={styles.btn} to="/login">LOGIN</Link>
                    <Link className={styles.btn} to="/register">CREATE ACCOUNT</Link>
                </div>

            </div>
            <div className={styles.row}>
                <div className={styles.title}>
                    SEE OUR CATALOG (PDF)
                </div>
                <button className={styles.btn}>
                    GO TO CATALOG
                </button>
            </div>
        </div>
    )
}