import { Link, useParams } from "react-router-dom";
import styles from "./ProductFiltersMenu.module.scss";
import FilteringSlider from "../fixcenter-components/filtering-slider/FilteringSlider";
import DropdownList from "../fixcenter-components/dropdown-list/DropdownList";
import { useEffect, useState } from "react";
import { getFixcenterFilteringCategories } from "../../services/products.service";
import { FeatureType } from "../../shared/models/feature-type.model";
import { FeatureFilter } from "./models/feature-filter.model";
import { ProductFilter } from "./models/product-filter.model";
import { FixcenterFilterDto } from "./models/fixcenter-filter-dto.model";
import SelectWithCheckboxes from "../fixcenter-components/select-with-checkboxes/SelectWithCheckboxes";
import FixcenterNavigation from "../fixcenter-components/fixcenter-navigation/FixcenterNavigation";
import { PRODUCT_SECOND_LEVEL_PATHS } from "../../shared/constants/breadcrumb.constants";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";

interface ProductFiltersMenuProps {
    searchByName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    fetchCategories: any;
    categoryName?: string;
}

export default function ProductFiltersMenu({ searchByName, fetchCategories, categoryName }: ProductFiltersMenuProps) {
    const [categoryInput, setCategoryInput] = useState<string>("");
    const { categoryId } = useParams();
    const [filters, setFilters] = useState<any[]>([]);
    const [featuresFilters, setFeaturesFilters] = useState<FeatureFilter[]>([]);
    const [clickClear, setClickClear] = useState<number>(0);

    const breadcrumbs = [...PRODUCT_SECOND_LEVEL_PATHS];
    if (categoryName !== undefined) {
        breadcrumbs[2] = { ...breadcrumbs[2], label: categoryName };
    }
    useBreadcrumbs(breadcrumbs);

    const handleCategoryInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCategoryInput(event.target.value);
        searchByName(event);
    };

    const fetchFilteringCategories = () => {
        if (categoryId) {
            getFixcenterFilteringCategories(categoryId).then((response: any): void => {
                setFilters(response.data as string[]);
            });
        }
    };

    useEffect(() => {
        if (categoryId) {
            fetchFilteringCategories();
        }
    }, [categoryId]);


    const handleSubmit = () => {
        const languageCode = localStorage.getItem("lang");
        if (categoryId && languageCode) {

            const productFilter: ProductFilter = {
                isCategory: true,
                parentProductId: categoryId,
                name: categoryInput
            };

            const fixcenterFilter: FixcenterFilterDto = {
                featureFilters: featuresFilters,
                productFilter: productFilter,
                languageCode: languageCode
            };
            fetchCategories(fixcenterFilter);
        }
    };

    const handleEnumValuesChange = (featureId: string, enumValues: string[] | boolean) => {
        setFeaturesFilters(prevFilters => {
            const existingFilterIndex = prevFilters.findIndex(f => f.featureId === featureId);
            let updatedFilters;

            if (existingFilterIndex >= 0) {
                updatedFilters = [...prevFilters];
                if (Array.isArray(enumValues) && enumValues.length > 0) {
                    updatedFilters[existingFilterIndex] = {
                        ...updatedFilters[existingFilterIndex],
                        values: enumValues
                    };
                } else if (typeof enumValues === 'boolean') {
                    if (!enumValues) {
                        updatedFilters.splice(existingFilterIndex, 1);
                    } else {
                        updatedFilters[existingFilterIndex] = {
                            ...updatedFilters[existingFilterIndex],
                            booleanValue: enumValues
                        };
                    }
                } else {
                    updatedFilters.splice(existingFilterIndex, 1);
                }
            } else if (Array.isArray(enumValues) && enumValues.length > 0) {
                updatedFilters = [
                    ...prevFilters,
                    {
                        featureId,
                        valueType: "enum",
                        values: enumValues
                    }
                ];
            } else if (typeof enumValues === 'boolean') {
                if (enumValues) {
                    updatedFilters = [
                        ...prevFilters,
                        {
                            featureId,
                            valueType: "boolean",
                            booleanValue: enumValues
                        }
                    ];
                } else {
                    updatedFilters = [...prevFilters];
                }
            } else {
                updatedFilters = [...prevFilters];
            }

            return updatedFilters;
        });
    };

    const handleRangeChange = (featureId: string, rangeFrom: string, rangeTo: string) => {
        setFeaturesFilters(prevFilters => {
            const existingFilterIndex = prevFilters.findIndex(f => f.featureId === featureId);
            let updatedFilters;

            if (existingFilterIndex >= 0) {
                updatedFilters = [...prevFilters];
                updatedFilters[existingFilterIndex] = {
                    ...updatedFilters[existingFilterIndex],
                    rangeFrom,
                    rangeTo,
                    valueType: "range"
                };
            } else {
                updatedFilters = [
                    ...prevFilters,
                    {
                        featureId,
                        rangeFrom,
                        rangeTo,
                        valueType: "range"
                    }
                ];
            }

            return updatedFilters;
        });
    };

    const getFilterType = (filter: any) => {
        let index = filter.featureType;
        return FeatureType[index];
    };

    const getFilterName = (filter: any) => {
        let filterName = filter.labelInEnglish;
        return filterName;
    };

    const clear = () => {
        setClickClear(clickClear + 1);
        if (categoryId) {
            const productFilter: ProductFilter = {
                isCategory: true,
                parentProductId: categoryId,
            }

            const fixcenterFilter: FixcenterFilterDto = {
                productFilter: productFilter,
            }
            fetchCategories(fixcenterFilter);
        }
    }

    return (
        <div className={styles.container}>
            {categoryName && <FixcenterNavigation />}
            <div className={styles.search_container}>
                <p>Search</p>
                <input
                    onChange={handleCategoryInputChange}
                    value={categoryInput}
                />
                {filters && filters.length > 0 &&
                    filters.map((filter, index) => (
                        <div key={index}>
                            {getFilterType(filter) === "NumericRange" &&
                                <FilteringSlider
                                    min={filter.minValue}
                                    max={filter.maxValue}
                                    onChange={({ min, max }) => { }}
                                    name={getFilterName(filter)}
                                    featureId={filter.id}
                                    onRangeChange={handleRangeChange}
                                    clear={clickClear}
                                    isInitiallyOpen={false}
                                />
                            }
                            {getFilterType(filter) === "Boolean" &&
                                <DropdownList
                                    values={filter.availableEnumValues ?? []}
                                    name={getFilterName(filter)}
                                    featureId={filter.id}
                                    onEnumValuesChange={handleEnumValuesChange}
                                    isBoolean={true}
                                    clear={clickClear}
                                    isInitiallyOpen={false}
                                />
                            }
                            {getFilterType(filter) === "Enum" &&
                                <DropdownList
                                    values={filter.availableEnumValues ?? []}
                                    name={getFilterName(filter)}
                                    featureId={filter.id}
                                    onEnumValuesChange={handleEnumValuesChange}
                                    isBoolean={false}
                                    clear={clickClear}
                                    isInitiallyOpen={false}
                                />
                            }

                            {getFilterType(filter) === "Numeric" &&
                                <SelectWithCheckboxes
                                    name={getFilterName(filter)}
                                    clear={clickClear}
                                    isInitiallyOpen={false}
                                />
                            }
                            {getFilterType(filter) === "Text" &&
                                <SelectWithCheckboxes
                                    name={getFilterName(filter)}
                                    clear={clickClear}
                                    isInitiallyOpen={false}
                                />
                            }
                        </div>
                    ))
                }
                <div className={styles.buttons_container}>
                    <button onClick={clear}>CLEAR</button>
                    <button onClick={handleSubmit}>SEARCH</button>
                </div>
            </div>
        </div>
    );
}