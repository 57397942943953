import FixcenterNavigation from '../../../components/fixcenter-components/fixcenter-navigation/FixcenterNavigation';
import CartButtons from '../cart-buttons/CartButtons';
import styles from './CartHeader.module.scss';
import { SHOPPING_CART_PATHS } from '../../../shared/constants/breadcrumb.constants';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';

export default function CartHeader() {
    useBreadcrumbs(SHOPPING_CART_PATHS);
    return (
        <div className={styles.container}>
            <FixcenterNavigation />
            <div className={styles.row}>
                <div className={styles.header}>
                    <p className={styles.notification}>CART</p>
                </div>
                <div className={styles.buttons_container}>
                    <CartButtons version={1}></CartButtons>
                </div>
            </div>
        </div>
    )
}