import { useTranslation } from 'react-i18next';
import styles from './register.module.scss';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { usePluginStore } from 'react-pluggable';

export default function Register() {
    const { t, i18n } = useTranslation('translation');
    const navigate = useNavigate();
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");

    return (
        <div className={styles.register_container}>
            <div className={styles.form_container}>
                <Renderer placement="loginAndRegistration:header" />
                <Renderer placement="registrationForm:inside" />
            </div>
        </div>
    )

}