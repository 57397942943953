import { useEffect, useState } from "react";
import { FeatureFilter } from "../../product-filters-menu/models/feature-filter.model";
import { getFixcenterFilteringCategories } from "../../../services/products.service";
import { FeatureType } from "../../../shared/models/feature-type.model";
import FilteringSlider from "../../fixcenter-components/filtering-slider/FilteringSlider";
import DropdownList from "../../fixcenter-components/dropdown-list/DropdownList";
import SelectWithCheckboxes from "../../fixcenter-components/select-with-checkboxes/SelectWithCheckboxes";
import styles from './OrderingTableFilters.module.scss';

export interface OrderingTableFiltersProps {
    productId: string
}

export default function OrderingTableFilters({ productId }: OrderingTableFiltersProps) {

    const [filters, setFilters] = useState<any[]>([]);
    const [featuresFilters, setFeaturesFilters] = useState<FeatureFilter[]>([]);


    useEffect(() => {
        if (productId) {
            fetchFilteringCategories();
        }
    }, [productId])

    const fetchFilteringCategories = () => {
        if (productId) {
            getFixcenterFilteringCategories(productId).then((response: any): void => {
                setFilters(response.data as string[]);
            });
        }
    };

    const handleEnumValuesChange = (featureId: string, enumValues: string[] | boolean) => {
        setFeaturesFilters(prevFilters => {
            const existingFilterIndex = prevFilters.findIndex(f => f.featureId === featureId);
            let updatedFilters;

            if (existingFilterIndex >= 0) {
                updatedFilters = [...prevFilters];
                if (Array.isArray(enumValues) && enumValues.length > 0) {
                    updatedFilters[existingFilterIndex] = {
                        ...updatedFilters[existingFilterIndex],
                        values: enumValues
                    };
                } else if (typeof enumValues === 'boolean') {
                    if (!enumValues) {
                        updatedFilters.splice(existingFilterIndex, 1);
                    } else {
                        updatedFilters[existingFilterIndex] = {
                            ...updatedFilters[existingFilterIndex],
                            booleanValue: enumValues
                        };
                    }
                } else {
                    updatedFilters.splice(existingFilterIndex, 1);
                }
            } else if (Array.isArray(enumValues) && enumValues.length > 0) {
                updatedFilters = [
                    ...prevFilters,
                    {
                        featureId,
                        valueType: "enum",
                        values: enumValues
                    }
                ];
            } else if (typeof enumValues === 'boolean') {
                if (enumValues) {
                    updatedFilters = [
                        ...prevFilters,
                        {
                            featureId,
                            valueType: "boolean",
                            booleanValue: enumValues
                        }
                    ];
                } else {
                    updatedFilters = [...prevFilters];
                }
            } else {
                updatedFilters = [...prevFilters];
            }

            return updatedFilters;
        });
    };

    const handleRangeChange = (featureId: string, rangeFrom: string, rangeTo: string) => {
        setFeaturesFilters(prevFilters => {
            const existingFilterIndex = prevFilters.findIndex(f => f.featureId === featureId);
            let updatedFilters;

            if (existingFilterIndex >= 0) {
                updatedFilters = [...prevFilters];
                updatedFilters[existingFilterIndex] = {
                    ...updatedFilters[existingFilterIndex],
                    rangeFrom,
                    rangeTo,
                    valueType: "range"
                };
            } else {
                updatedFilters = [
                    ...prevFilters,
                    {
                        featureId,
                        rangeFrom,
                        rangeTo,
                        valueType: "range"
                    }
                ];
            }

            return updatedFilters;
        });
    };

    const getFilterType = (filter: any) => {
        let index = filter.featureType;
        return FeatureType[index];
    };

    const getFilterName = (filter: any) => {
        let filterName = filter.labelInEnglish;
        return filterName;
    };


    return (
        <div className={styles.container}>
            {filters && filters.length > 0 &&
                filters.map((filter, index) => (
                    <div className={styles.element} key={index}>
                        {getFilterType(filter) === "NumericRange" &&
                            <FilteringSlider
                                min={filter.minValue}
                                max={filter.maxValue}
                                onChange={({ min, max }) => { }}
                                name={getFilterName(filter)}
                                featureId={filter.id}
                                onRangeChange={handleRangeChange}
                                isInitiallyOpen={true}
                            />
                        }
                        {getFilterType(filter) === "Boolean" &&
                            <DropdownList
                                values={filter.availableEnumValues ?? []}
                                name={getFilterName(filter)}
                                featureId={filter.id}
                                onEnumValuesChange={handleEnumValuesChange}
                                isBoolean={true}
                                isInitiallyOpen={true}
                            />
                        }
                        {getFilterType(filter) === "Enum" &&
                            <DropdownList
                                values={filter.availableEnumValues ?? []}
                                name={getFilterName(filter)}
                                featureId={filter.id}
                                onEnumValuesChange={handleEnumValuesChange}
                                isBoolean={false}
                                isInitiallyOpen={true}
                            />
                        }

                        {getFilterType(filter) === "Numeric" &&
                            <SelectWithCheckboxes
                                name={getFilterName(filter)}
                                isInitiallyOpen={true}
                            />
                        }
                        {getFilterType(filter) === "Text" &&
                            <SelectWithCheckboxes
                                name={getFilterName(filter)}
                                isInitiallyOpen={true}
                            />
                        }
                    </div>
                ))
            }
        </div>
    )
}