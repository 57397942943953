import { useTranslation } from "react-i18next";
import { UserLogin } from "../../../auth/login/models";
import { useNavigate } from "react-router";
import * as loginService from "../../../auth/services/auth.service";
import styles from './LoginForm.module.scss';
import { Form, Formik } from 'formik';
import { usePluginStore } from 'react-pluggable';
import LoginMenu from "../../../components/login-menu/LoginMenu";
import BasicInformation from "../../../components/basic-information/BasicInformation";

let userCredentials: UserLogin = {
    loginIdentifier: "",
    password: ""
};

const LoginFormComponent = (props: any) => {
    const { t, i18n } = useTranslation('translation');
    const navigate = useNavigate();
    const pluginStore: any = usePluginStore();
    let redirectTo: string = '';
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");
    function logIn(userCredentials: UserLogin): void {
        if (props.redirectToOrder === true) {
            redirectTo = '/order'
        }
        else {
            redirectTo = '/'
        }
        loginService.logIn(userCredentials, navigate, redirectTo);
    }

    return (
        <div className={styles.container}>
            <div className={styles.menu}>
                <LoginMenu />
            </div>
            <Formik initialValues={{ ...userCredentials }} onSubmit={(values) =>
                logIn(values)}>
                {({ values, handleChange, handleBlur }) => (
                    <Form className={styles.form_container} onMouseEnter={() => { }}>
                        <h4>Sign in</h4>
                        <div className={styles.field_container}>
                            <input
                                className={styles.field_input}
                                name="loginIdentifier"
                                type="text"
                                value={values.loginIdentifier}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="email"
                            />
                        </div>
                        <div className={styles.field_container}>
                            <input
                                className={styles.field_input}
                                name="password"
                                type="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Password"
                            />
                        </div>
                        <div className={styles.submit_button_container}>
                            <button type="submit">{t("loginForm.submit")}</button>
                        </div>
                        <div className={styles.remember_reset_container}>
                            <Renderer placement="resetPassword:inside" />
                            <Renderer placement="loginOptions:inside" />
                        </div>
                    </Form>
                )}
            </Formik>
            <div className={styles.basic_information}>
                <BasicInformation />
            </div>
        </div>
    )
}

export default LoginFormComponent;

