import { useParams } from 'react-router-dom';
import FixcenterNavigation from '../../components/fixcenter-components/fixcenter-navigation/FixcenterNavigation';
import ProductFiltersMenu from '../../components/product-filters-menu/ProductFiltersMenu';
import styles from './SearchResultsPage.module.scss';
import { useEffect, useState } from 'react';
import { FixcenterFilterDto } from '../../components/product-filters-menu/models/fixcenter-filter-dto.model';
import { getFixcenterCategories, getFixcenterSearchedProducts } from '../../services/products.service';
import { Category } from '../../components/products-page/components/products-categories/models/Category.model';
import { ProductFilter } from '../../components/product-filters-menu/models/product-filter.model';
import SecondLevelProductCard from '../../components/second-level-product-card/SecondLevelProductCard';

export default function SearchResultsPage() {
    const { value } = useParams();
    const [isAnimationVisible, setIsAnimationVisible] = useState<boolean>(false);
    const [products, setProducts] = useState<Category[]>([]);

    const fetchCategories = async (fixcenterFilter: ProductFilter) => {
        try {
            if (value !== undefined) {
                setIsAnimationVisible(true);
                getFixcenterSearchedProducts(fixcenterFilter).then((response: any) => {
                    if (response) {
                        const categoriesResponse = response.data as Category[];
                        setProducts(categoriesResponse);
                        setIsAnimationVisible(false);
                    }
                })
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (value) {
            const productFilter: ProductFilter = {
                isCategory: true,
                name: value
            }

            fetchCategories(productFilter);
        }
    }, [value]);
    return (
        <div className={styles.container}>
            <div className={styles.filters_menu}>
                <FixcenterNavigation />
            </div>
            <div className={styles.content}>
                <div className={styles.category_header}>
                    <p>Search results</p>
                </div>
                <div className={styles.cards_container}>
                    {products.map((product, index) => (
                        <div key={index} className={styles.single_product}>
                            <SecondLevelProductCard product={product} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}