import styles from './MyOffersTable.module.scss';

export default function MyOffersTable() {
    return (
        <div className={styles.table_container}>
            <div className={`${styles.row} ${styles.no_hover}`}>
                <div className={`${styles.column}  ${styles.bold}`}>NUMBER</div>
                <div className={`${styles.column}  ${styles.bold}`}>DATE</div>
                <div className={`${styles.column}  ${styles.bold}`}>STATUS</div>
            </div>
            <div className={`${styles.row}`}>
                <div className={`${styles.column}`}>20425</div>
                <div className={`${styles.column}`}>06/11/2023 12:11:05</div>
                <div className={`${styles.column}`}>PAID</div>
            </div>
        </div>
    )
}