import CartButtons from '../cart-buttons/CartButtons';
import styles from './CartBasketTable.module.scss';

export default function CartBasketTable() {
    return (
        <div className={styles.container}>
            <div className={`${styles.header} ${styles.underlined_row}`}>
                YOUR CART IS EMPTY
            </div>
            <div className={`${styles.sub_header}`}>
                <div className={` ${styles.underlined_row} ${styles.shorter_row}`}>
                    Total:
                </div>
                <div className={styles.button_container}>
                    <button>Clear basket</button>
                </div>
            </div>
            <div className={`${styles.underlined_row} ${styles.shorter_row}`}>
                <span className={styles.bold}>Net price</span> <br></br> 0 €
            </div>
            <div className={`${styles.underlined_row} ${styles.shorter_row} ${styles.text_side}`} >
                <span className={styles.bold}>Shipping cost</span> <br></br> 0.00 €
            </div>
            <div className={styles.buttons_container}>
                <div className={styles.buttons_wrapper}>
                    <CartButtons version={1}></CartButtons>
                </div>
            </div>
        </div >
    )
}