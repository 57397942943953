import { Breadcrumb } from "../../store/reducers";

export const PRODUCT_FIRST_LEVEL_PATHS: Breadcrumb[] = [
    { label: 'Home', link: '/' },
    { label: 'Products', link: '/webshop' }
];

export const SHOPPING_CART_PATHS: Breadcrumb[] = [
    { label: 'Home', link: '/' },
    { label: 'Shopping cart', link: '' }
];

export const MY_ORDERS_PATHS: Breadcrumb[] = [
    { label: 'Home', link: '/' },
    { label: 'My orders', link: '' }
];

export const MY_BACK_ORDERS_PATHS: Breadcrumb[] = [
    { label: 'Home', link: '/' },
    { label: 'My back orders', link: '' }
];

export const MY_INVOICES_PATHS: Breadcrumb[] = [
    { label: 'Home', link: '/' },
    { label: 'My invoices', link: '' }
];

export const MY_OFFERS_PATHS: Breadcrumb[] = [
    { label: 'Home', link: '/' },
    { label: 'My offers', link: '' }
];

export const MY_BUILDING_SITES_PATHS: Breadcrumb[] = [
    { label: 'Home', link: '/' },
    { label: 'Building sites', link: '' }
];

export const MY_BUILDING_SITES_FORM_PATHS: Breadcrumb[] = [
    { label: 'Home', link: '/' },
    { label: 'Building sites', link: 'Cart/BuildingSites/List' },
    { label: 'New building site', link: 'Cart/BuildingSites/List' }
];

export const PRODUCT_SECOND_LEVEL_PATHS: Breadcrumb[] = [
    { label: 'Home', link: '/' },
    { label: 'Products', link: '/webshop' },
    { label: '', link: '' },
];

export const PRODUCT_THIRD_LEVEL_PATHS: Breadcrumb[] = [
    { label: 'Home', link: '/' },
    { label: 'Products', link: '/webshop' },
    { label: '', link: '' },
    { label: '', link: '' },
];





