export enum FeatureType {
    Boolean,
    Numeric,
    NumericRange,
    Text,
    Enum,
    TextArea,
    Date,
    Image,
    ObjectLink,
    Table,
    Binary,
}