import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./ProductsCarousel.module.scss"
import { Category } from "../products-categories/models/Category.model";
import axios from "axios";
import FirstLevelProductCard from "../../../first-level-product-card/FirstLevelProductCard";
import { getFixcenterCategories } from "../../../../services/products.service";
import { ProductFilter } from "../../../product-filters-menu/models/product-filter.model";
import { FixcenterFilterDto } from "../../../product-filters-menu/models/fixcenter-filter-dto.model";

export default function ProductsCarousel(props: any) {

    const [selectedImage, setSelectedImage] = useState<number>(0);
    const [mainCategories, setMainCategories] = useState<Category[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const guidEmpty = "00000000-0000-0000-0000-000000000000";

    const Arrow = (props: any) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "grey" }}
                onClick={onClick}
            />
        );
    }

    const selectFirstProduct = (product: Category) => {
        props.handleSetFirstProduct(product);
    }

    const fetchCategories = async () => {
        try {
            const productFilter: ProductFilter = {
                isCategory: true,
                parentProductId: guidEmpty,
            }

            const fixcenterFilter: FixcenterFilterDto = {
                productFilter: productFilter,
            }

            getFixcenterCategories(fixcenterFilter).then((response: any) => {
                if (response) {
                    const categoriesResponse = response.data as Category[];
                    setMainCategories(categoriesResponse);
                    selectFirstProduct(response?.data[0] as Category);
                    setIsLoaded(true);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 750,
        rows: 3,
        slidesPerRow: 2,
        autoplay: false,
        autoplaySpeed: 4000,
        cssEase: "linear",
        pauseOnHover: true,
        nextArrow: <Arrow />,
        prevArrow: <Arrow />
    };


    const handleImageSelect = (index: number): void => {
        setSelectedImage(index);
        props.onImageSelect(index);
    };

    return (
        <div className={styles.wrapper}>
            {isLoaded &&
                <Slider {...settings}>
                    {mainCategories.map((category, index) => (
                        <div key={index} onMouseEnter={() => handleImageSelect(index)}>
                            <div className={styles.item} key={index}>
                                <FirstLevelProductCard category={category} onImageSelect={props.onImageSelect} />
                            </div>
                        </div>
                    ))}
                </Slider>
            }
        </div>
    );
}
