import styles from "./FixcenterSupport.module.scss";

export interface FixcenterSupportProps {
    variant: number; // variant 1 -> mainpage version / variant 2 -> productsthirdlevel version
}

const FixcenterSupport: React.FC<FixcenterSupportProps> = ({ variant }) => {
    const ourSalesImage = require('../../assets/img/fixcenter_our_sales.jpg');
    const ourSupportImage = require('../../assets/img/fixcenter_our_support.jpg');
    const calculationProgram = require('../../assets/img/fixcenter_calculation_program.png');
    return (
        <>
            {variant === 1 && (
                <div className={styles.container}>
                    <div className={styles.part}>
                        <img src={calculationProgram} alt="Our Sales" />
                        <div className={styles.text}>
                            <div className={styles.first_column}>
                                <span className={styles.normal}>CALCULATION</span>
                                <span className={styles.bold}>PROGRAM</span>
                                <span className={styles.light}>See details   &#187;</span>
                            </div>
                            <div className={styles.second_column}>
                                <div className={styles.hover_text}>
                                    Calculate your project needs up to the last bolt. Surprisingly easy - to - use and highly time - saving.
                                </div>
                                <button>TRY IT</button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.part}>
                        <img src={ourSalesImage} alt="Our Support" />
                        <div className={styles.text}>
                            <div className={styles.first_column}>
                                <span className={styles.normal}>OUR</span>
                                <span className={styles.bold}>SALES</span>
                                <span className={styles.light}>See details   &#187;</span>
                            </div>
                            <div className={styles.second_column}>
                                <div className={styles.hover_text}>
                                    Get personalized advice at any given time. Contact your personal sales agent.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.part}>
                        <img src={ourSupportImage} alt="Our Support" />
                        <div className={styles.text}>
                            <div className={styles.first_column}>
                                <span className={styles.normal}>OUR</span>
                                <span className={styles.bold}>SUPPORT</span>
                                <span className={styles.light}>See details   &#187;</span>
                            </div>
                            <div className={styles.second_column}>
                                <div className={styles.hover_text}>
                                    Any questions on your order or delivery? Reach out and talk to the Fixcenter team!
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            )}
            {
                variant === 2 && (
                    <div className={styles.container}>
                        <div className={styles.left_side}>
                            <div className={styles.text}>
                                <span>OUR</span>
                                <span>SALES</span>
                            </div>
                            <div className={styles.hover_text}>
                                Get personalized advice at any given time. Contact your personal sales agent.
                            </div>
                        </div>
                        <div className={styles.right_side}>
                            <div className={styles.text}>
                                <span>OUR</span>
                                <span>SUPPORT</span>
                            </div>
                            <div className={styles.hover_text}>
                                Any questions on your order or delivery? Reach out and talk to the Fixcenter team!
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default FixcenterSupport;
