import styles from './CartOrderForm.module.scss';

export default function CartOrderForm() {

    return (
        <div className={styles.form}>
            <div className={`${styles.form_row} ${styles.first_row}`}>
                <div className={styles.input_label}>SELECTED BUILDING SITE:</div>
                <select>
                    <option>AV PRINCE BAUDOUIN 3</option>
                </select>
            </div>
            <div className={`${styles.form_row} ${styles.second_row}`}>
                <div className={styles.column}>
                    <div className={styles.input_label}>CONTACT</div>
                    <div className={styles.input_label}>PHONE</div>
                </div>
                <div className={`${styles.column} ${styles.input_column}`}>
                    <input placeholder='John' />
                    <input placeholder='02 732 32 59' />
                </div>
                <div className={styles.column}>
                    <div className={styles.input_label}>REFERENCE</div>
                    <div className={styles.input_label}>ISSELFPICKUP</div>
                    <div className={styles.input_label}>COMMENTS</div>
                </div>
                <div className={styles.column}>
                    <input />
                    <input type='checkbox' />
                    <textarea />
                </div>
            </div>
        </div >
    )
}