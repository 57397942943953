import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Breadcrumb, clearBreadcrumbs, setBreadcrumbs } from '../store/reducers';

const useBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearBreadcrumbs());
        dispatch(setBreadcrumbs(breadcrumbs));
    }, [dispatch, breadcrumbs]);
};

export default useBreadcrumbs;
