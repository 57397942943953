import { useEffect, useRef, useState } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import styles from './AppContainer.module.scss';
import ProductsPage from '../../components/products-page/ProductsPage';
import { usePluginStore } from 'react-pluggable';
import Header from '../../shared/header/Header';
import CmsContainer from '../cms-container/CmsContainer';
import Register from '../../auth/register/Register';
import Login from '../../auth/login/Login';
import { CAREER, COMPANY, CONTACT_US, ENGINEERING, SERVICES, VISIT_US } from '../../shared/constants/cms-codes.constants';
import { CmsTitlesAndUrls } from '../cms-container/models/cms-titles-url-by-code';
import { getCmsUrlAndTitleByLanguagAndCode } from '../cms-container/services';
import ProductsFirstLevel from '../products-first-level/ProductsFirstLevel';
import ProductsSecondLevel from '../products-second-level/ProductsSecondLevel';
import * as authService from '../../auth/services/auth.service';
import ProductsThirdLevel from '../products-third-level/ProductsThirdLevel';
import LoginOrRegisterFixcenter from '../../components/login-or-register-fixcenter/LoginOrRegisterFixcenter';
import ShoppingCartContainer from '../shopping-cart-container/ShoppingCartContainer';
import SearchResultsPage from '../search-results-page/SearchResultsPage';
import MyOrdersContainer from '../my-orders-container/MyOrdersContainer';
import MyInvoicesContainer from '../my-invoices-container/MyInvoicesContainer';
import MyOffersContainer from '../my-offers-container/MyOffersContainer';
import MyBuildingSites from '../my-building-sites/MyBuildingSites';
import AddBuildingSiteForm from '../my-building-sites/add-building-site-form/AddBuildingSiteForm';
import ResetPassword from '../../auth/reset-password/components/reset-password/ResetPassword';

export default function AppContainer() {
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");
    const [cartItemsChanged, setCartItemsChanged] = useState<boolean>(false);
    const [titlesAndUrls, setTitlesAndUrls] = useState<CmsTitlesAndUrls>({});
    const [loading, setLoading] = useState(true);
    const cmsContainerRef = useRef<HTMLElement | null>(null);
    const location = useLocation();
    const [cmsContainerLoaded, setCmsContainerLoaded] = useState(false);

    const getTitleAndUrlForHeader = async (): Promise<void[]> => {
        const codes = [SERVICES, COMPANY, CAREER, CONTACT_US, ENGINEERING, VISIT_US];
        const savedLanguageCode = localStorage.getItem('lang');
        if (savedLanguageCode) {
            const promises = codes.map(async (code) => {
                const response = await getCmsUrlAndTitleByLanguagAndCode(code, savedLanguageCode);
                setTitlesAndUrls((prevTitlesAndUrls) => ({
                    ...prevTitlesAndUrls,
                    [code]: response.data,
                }));
            });
            await Promise.all(promises);
            setLoading(false);
        }

        return Promise.resolve([]);
    };

    useEffect(() => {
        getTitleAndUrlForHeader();
    }, [localStorage.getItem('lang')]);

    useEffect(() => {
    }, [loading, titlesAndUrls]);

    const scrollToCmsContainer = () => {
        if (cmsContainerRef.current) {
            window.scrollTo({
                top: cmsContainerRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        scrollToCmsContainer();
    }, [location.pathname, cmsContainerLoaded]);

    return (
        <div className={styles.main_container}>
            <div className={styles.header}>
                <Header cartItemsChanged={cartItemsChanged} />
            </div>
            <div className={styles.content}>
                <Routes>
                    <Route path='/:language?' element={<Renderer placement="page:fixcenterSlider" />} />
                    <Route path='/:language?/login' Component={Login} />
                    <Route path='/:language?/register' Component={Register} />
                    <Route path='/*' element={<CmsContainer />} />
                    {<Route path='/:language?/webshop' element={authService.checkLogInStatus() === true ? <ProductsFirstLevel /> : <Login />}></Route>}
                    {<Route path='/:language?/webshop/category/:categoryName?/:categoryId?' element={authService.checkLogInStatus() === true ? <ProductsSecondLevel /> : <Login />}></Route>}
                    {<Route path='/:language?/webshop/product/:categoryName?/:categoryId?/:productId?' element={authService.checkLogInStatus() === true ? <ProductsThirdLevel /> : <Login />}></Route>}
                    {<Route path='/:language?/search/:value?' element={authService.checkLogInStatus() === true ? <SearchResultsPage /> : <Login />}></Route>}
                    <Route path={`/:language?${titlesAndUrls.SERVICES?.url}`} element={<CmsContainer code={SERVICES} />}></Route>
                    <Route path={`/:language?${titlesAndUrls.COMPANY?.url}`} element={<CmsContainer code={COMPANY} />}></Route>
                    <Route path={`/:language?${titlesAndUrls.CAREER?.url}`} element={<CmsContainer code={CAREER} />}></Route>
                    <Route path={`/:language?${titlesAndUrls.CONTACT_US?.url}`} element={<CmsContainer code={CONTACT_US} />}></Route>
                    <Route path={`/:language?${titlesAndUrls.ENGINEERING?.url}`} element={<CmsContainer code={ENGINEERING} />}></Route>
                    <Route path={`/:language?${titlesAndUrls.VISIT_US?.url}`} element={<CmsContainer code={VISIT_US} />}></Route>
                    <Route path={`/:language?/Home/Product`} element={<LoginOrRegisterFixcenter />}></Route>
                    {<Route path={`/:language?/Cart`} element={authService.checkLogInStatus() === true ? <ShoppingCartContainer /> : <Login />} />}
                    {<Route path={`/:language?/Cart/Order/List`} element={authService.checkLogInStatus() === true ? <MyOrdersContainer /> : <Login />} />}
                    {<Route path={`/:language?/Cart/BackOrder/List`} element={authService.checkLogInStatus() === true ? <MyOrdersContainer /> : <Login />} />}
                    {<Route path={`/:language?/Cart/Invoices/List`} element={authService.checkLogInStatus() === true ? <MyInvoicesContainer /> : <Login />} />}
                    {<Route path={`/:language?/Cart/Offers/List`} element={authService.checkLogInStatus() === true ? <MyOffersContainer /> : <Login />} />}
                    {<Route path={`/:language?/Cart/BuildingSites/List`} element={authService.checkLogInStatus() === true ? <MyBuildingSites /> : <Login />} />}
                    {<Route path={`/:language?/BuildingSites/AddBuildingSite`} element={authService.checkLogInStatus() === true ? <AddBuildingSiteForm /> : <Login />} />}
                    {<Route path="/:language?/reset-password/*" element={<ResetPassword />} />}
                </Routes >
            </div >
            <div className={styles.footer}>
                <Renderer placement="page:fixcenterFooter" />
            </div>
        </div >
    );

}
