import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import * as configuration from './configuration/configuration';
import AppContainer from './containers/app-container/AppContainer';
import ResetPaswordContainer from './containers/reset-password-container/ResetPaswordContainer';
import { ToastContainer } from 'react-toastify';
import {
    createPluginStore,
    PluginProvider,
    RendererPlugin
} from "react-pluggable";
import { Provider, useDispatch } from 'react-redux';
import store from './store/store';
import { PluginEvent } from './plugins/Extensions/pluginEvent';
import OrderEmailConfirmation from './components/order-email-confirmation/OrderEmailConfirmation';
import { getDefaultCurrency } from './shared/services/get-default-currency.service';
import SetFixcenterFooterPlugin from './plugins/SetFixcenterFooterPlugin';
import RegisterCompanyPlugin from './plugins/RegisterCompanyPlugin';
import CreateNewAccountHeaderPlugin from './plugins/CreateNewAccountHeaderPlugin';
import ResetPasswordPlugin from './plugins/ResetPasswordPlugin';
import RegisterNowPlugin from './plugins/RegisterNowPlugin';
import LoginFormPlugin from './plugins/LoginFormPlugin';
import FixcenterProductsPagePlugin from './plugins/FixcenterProductsPagePlugin';

const pluginStore = createPluginStore();

pluginStore.install(new RendererPlugin());
pluginStore.install(new SetFixcenterFooterPlugin());
pluginStore.install(new LoginFormPlugin());
pluginStore.install(new RegisterNowPlugin());
pluginStore.install(new ResetPasswordPlugin());
pluginStore.install(new RegisterCompanyPlugin());
pluginStore.install(new CreateNewAccountHeaderPlugin());
pluginStore.install(new FixcenterProductsPagePlugin());


function App() {

    const dispatch = useDispatch();
    const location = useLocation();
    const languageCodes: string[] = ["pl", "en", "chi", "nl", "de"];

    function getLanguageCode(): string | null {
        return localStorage.getItem("lang");
    }

    useEffect(() => {
        const currentPath = location.pathname;
        const currentLanguage = window.location.pathname.split("/")[1];

        if (currentLanguage !== getLanguageCode() && languageCodes.includes(currentLanguage)) {
            const newPath = currentPath.replace(`/${currentLanguage}`, `/${getLanguageCode()}`);
            return window.history.replaceState(null, '', newPath);
        } else if (!currentPath.startsWith(`/${getLanguageCode()}`) && !languageCodes.includes(currentLanguage)) {
            const newPath = `/${getLanguageCode()}${currentPath}`;
            return window.history.replaceState(null, '', newPath);
        }
    }, [location]);


    configuration.configure();
    return (
        <PluginProvider pluginStore={pluginStore}>
            <Provider store={store}>
                <ToastContainer />
                <Routes>
                    <Route path="/*" Component={AppContainer}></Route>
                    <Route path="/order-email-confirmation/*" Component={OrderEmailConfirmation}></Route>
                </Routes>
            </Provider>
        </PluginProvider>
    );
}


export default App;
