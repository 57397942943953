import { useTranslation } from 'react-i18next';
import styles from './CreateNewAccountHeader.module.scss';

const CreateNewAccountHeader = () => {
    const { t, i18n } = useTranslation('translation');

    return (
        <h1 className={styles.header}>{t('register.createNewAccount')}</h1>
    )
};
export default CreateNewAccountHeader;