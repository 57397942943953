import styles from './MyBuildingSitesTable.module.scss';

export default function MyBuildingSitesTable() {
    return (
        <div className={styles.container}>
            <div className={`${styles.form_row} ${styles.first_row}`}>
                <div className={styles.input_label}>SELECTED BUILDING SITE:</div>
                <select>
                    <option>AV PRINCE BAUDOUIN 3</option>
                </select>
            </div>
            <div className={styles.table_container}>
                <div className={`${styles.row} ${styles.no_hover}`}>
                    <div className={`${styles.column}  ${styles.bold}`}>ADDRESS</div>
                </div>
                <div className={`${styles.row}`}>
                    <div className={`${styles.column}`}>20230420_new_bSite5, testing414, 414, testing414, Belgium</div>
                    <div className={`${styles.column}`}><button>Edit</button></div>
                </div>
                <div className={`${styles.row} ${styles.odd_row}`}>
                    <div className={`${styles.column}`}>20230420_new_bSite5, testing414, 414, testing414, Belgium</div>
                    <div className={`${styles.column}`}><button>Edit</button></div>
                </div>
            </div >
        </div>
    )
}