//FILE EMPTY GUID
export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

//FIRST LEVEL PRODUCT CARD
export const FIRST_LEVEL_PRODUCT_CARD_WIDTH = 600;
export const FIRST_LEVEL_PRODUCT_CARD_HEIGHT = 600;

//second LEVEL PRODUCT CARD
export const SECOND_LEVEL_PRODUCT_CARD_WIDTH = 600;
export const SECOND_LEVEL_PRODUCT_CARD_HEIGHT = 600;
