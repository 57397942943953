import { Link } from 'react-router-dom';
import styles from './BasicInformation.module.scss';

export default function BasicInformation() {
    return (
        <div className={styles.container}>
            <div className={styles.header}>Basic information</div>
            <div className={styles.text}>The first 3D modeling installation engineering application in the cloud</div>
            <button><Link to='/Engineering'>More details</Link></button>
        </div>
    )
}