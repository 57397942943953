import { IPlugin, PluginStore } from "react-pluggable";
import RegisterCompanyForm from "./components/RegisterCompanyForm/RegisterCompanyForm";

class RegisterCompanyPlugin implements IPlugin {
    pluginStore!: PluginStore;

    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }

    getPluginName(): string {
        return 'RegisterCompany@1.0.0';
    }

    getDependencies(): string[] {
        return [];
    }

    deactivate(): void { }

    activate(): void {
        this.pluginStore.executeFunction('Renderer.add', 'registrationForm:inside', () => (
            <RegisterCompanyForm></RegisterCompanyForm>
        ));
    }
}

export default RegisterCompanyPlugin;