import MyInvoicesHeader from './my-invoices-header/MyInvoicesHeader';
import MyInvoicesTable from './my-invoices-table/MyInvoicesTable';
import styles from './MyInvoicesContainer.module.scss';

export default function MyInvoicesContainer() {

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.content}>
                    <MyInvoicesHeader />
                    <MyInvoicesTable />
                </div>
            </div>
        </div>
    )
}