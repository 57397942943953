import styles from "./ToggleSwitch.module.scss";

const twoDimensionsIcon = require("../../assets/icons/2d.png");
const threeDimensionsIcon = require("../../assets/icons/3d.png");

const ToggleSwitch = (props: any) => {
    const { toggled } = props;

    return (
        <div className={styles.container}>
            {toggled ? (
                <div className={styles.three_dimensions}>
                    <div className={styles.image_container}>
                        <img src={threeDimensionsIcon} alt="3D Icon" />
                    </div>
                    <div className={styles.text_container}>
                        <p>3D</p>
                    </div>
                </div>
            ) : (
                <div className={styles.two_dimensions}>
                    <div className={styles.image_container}>
                        <img src={twoDimensionsIcon} alt="2D Icon" />
                    </div>
                    <div className={styles.text_container}>
                        <p>2D</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ToggleSwitch;
