import { IPlugin, PluginStore } from "react-pluggable";
import CreateNewAccountHeader from "./components/RegisterComponents/CreateNewAccountHeader/CreateNewAccountHeader";

class CreateNewAccountHeaderPlugin implements IPlugin {

    pluginStore!: PluginStore;

    getPluginName(): string {
        return "CreateNewAccountHeader@1.0.0"
    }
    getDependencies(): string[] {
        return [];
    }
    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }
    activate(): void {
        this.pluginStore.executeFunction("Renderer.add", "loginAndRegistration:header", () => (
            <CreateNewAccountHeader></CreateNewAccountHeader>
        ));
    }
    deactivate(): void { }
}

export default CreateNewAccountHeaderPlugin;