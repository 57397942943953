import { useEffect, useState } from "react";
import { Category } from "../products-page/components/products-categories/models/Category.model";
import styles from "./SecondLevelProductCard.module.scss";
import { Link, useParams } from "react-router-dom";
import { getDimensionedImage } from "../../shared/services/get-attachments.service";
import { EMPTY_GUID, SECOND_LEVEL_PRODUCT_CARD_HEIGHT, SECOND_LEVEL_PRODUCT_CARD_WIDTH } from "../../shared/constants/images-resolution.constants";
const noImage = require('../../assets/img/Fixcenter-noimage.png')
interface Props {
    product: Category;
    categoryName?: string;
}


export default function SecondLevelProductCard({ product }: Props) {

    const [productImage, setProductImage] = useState<string>();
    const { categoryName } = useParams();

    const fetchCategoryImages = (category: Category) => {
        if (category.imageIds[0] !== null && category.imageIds[0] !== undefined && category.imageIds[0] !== EMPTY_GUID) {
            getDimensionedImage(category.imageIds[0], SECOND_LEVEL_PRODUCT_CARD_WIDTH, SECOND_LEVEL_PRODUCT_CARD_HEIGHT).then((response) => {
                if (response) {
                    setProductImage(response)
                }
            })
        }
    }

    useEffect(() => {
        if (product.id !== null) {
            fetchCategoryImages(product);
        }
    }, [product])

    return (
        <div className={styles.container}>
            <Link to={`/webshop/product/${categoryName}/${product.parentProductId}/${product.id}`} className={styles.product_image}>
                <img src={productImage ? productImage : noImage} alt={"Product"} />
            </Link>
            <Link to={`/webshop/product/${categoryName}/${product.id}`} className={styles.product_name}>
                {product.translation ?
                    product.translation :
                    product.name
                }
            </Link>
        </div >
    );
}
