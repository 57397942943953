import axios from "axios"
import { User } from "../../components/client-panel/components/my-account-info/models/user.model";

export const getIfUserExistance = (email: string) => {
    return axios.get<boolean>(`user/checkIfExistByEmail/${email}`);
}

export const getCurrentUser = () => {
    return axios.get<User>(`user/getCurrentUser`);
}

