import MyOffersHeader from './my-offers-header/MyOffersHeader';
import MyOffersTable from './my-offers-table/MyOffersTable';
import styles from './MyOffersContainer.module.scss';

export default function MyOffersContainer() {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <MyOffersHeader />
                <MyOffersTable />
            </div>
        </div>
    )
}