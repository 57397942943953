import axios from 'axios'
import { Filters } from '../models/filters.model';
import { Category } from '../components/products-page/components/products-categories/models/Category.model';
import { FixcenterFilterDto } from '../components/product-filters-menu/models/fixcenter-filter-dto.model';
import { ProductFilter } from '../components/product-filters-menu/models/product-filter.model';

export const getProductById = (id: string): any => {
    return axios.get<string>(`products/product/${id}`)
}

export const getProductImage = (productId: string): any => {
    return axios.get("Images/products/" + productId);
}
export const getFixcenterProductByCategoryId = (id: string): any => {
    return axios.get(`products/fixcenterProductsByCategoryId/${id}`);
}

export const getFixcenterCategories = (fixcenterFilter: FixcenterFilterDto): any => {
    return axios.post<Category[]>("categories/fixcenterProducts", fixcenterFilter)
}

export const getFixcenterFilteringCategories = (categoryId: string): any => {
    return axios.get(`products/fixcenterFilteringCategories/${categoryId}`);
}

export const getFixcenterSearchedProducts = (filters: ProductFilter): any => {
    return axios.post<Category[]>("products/fixcenterSearchedProducts", filters)
}
