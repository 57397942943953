import { Link, useNavigate } from 'react-router-dom';
import styles from './CartButtons.module.scss';

export interface CartButtonsProps {
    version: number;
}

export default function CartButtons({ version }: CartButtonsProps) {
    const navigate = useNavigate();

    const navigator = () => {
        navigate('/webshop')
    }
    return (
        <div className={styles.buttons_container}>
            <button>
                <Link to="/BuildingSites/AddBuildingSite">Add building site</Link>
            </button>
            {version === 1 &&
                <>
                    <button onClick={navigator}>Continue shopping</button>
                    <button disabled={true}>Order</button>
                </>
            }
        </div>
    )
}