
import { BrowserRouter as Router, Route, useNavigate, } from 'react-router-dom';
import axios from 'axios';
import { Field, Formik, Form, FormikErrors } from 'formik';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { RegisterFormValues, RegisterDto } from '../../../auth/register/models';
import { Country } from '../../../components/order/models';
import { Language } from '../../../shared/models';
import styles from './RegisterCompanyForm.module.scss';
import * as yup from 'yup';
import { COMPANY_ACCOUNT_TYPE } from '../../../shared/constants/account-type.constants';
import { getFormSetting } from '../../../containers/cms-container/services';
import { FormSetting } from '../../../containers/cms-container/models';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import LoginMenu from '../../../components/login-menu/LoginMenu';
import BasicInformation from '../../../components/basic-information/BasicInformation';

const RegisterCompanyForm = () => {

    const [countries, setCountries] = useState<Country[]>([]);
    const [languages, setLanguages] = useState<Language[]>([]);
    const [checkIfTermsAccepted, setCheckIfTermsAccepted] = useState(false);
    const { t, i18n } = useTranslation('translation');
    const [formSetting, setFormSetting] = useState<FormSetting>();
    const [formValid, setFormValid] = useState(false);
    const [captchaValue, setCaptchaValue] = useState<string>('');
    const navigate = useNavigate();
    const showError = (message: string) => toast.error(message);
    const [hasFormBeenSubmited, setHasFormBeenSubmited] = useState(false);

    const fetchFormSetting = (): void => {
        getFormSetting().then((response: any) => {
            const formSettings = response?.data as FormSetting;
            setFormSetting(formSettings);
        })
    }

    useEffect(() => {
        axios.get('countries')
            .then(response => {
                const countries = response?.data as Country[];
                setCountries(countries || []);
            })
            .catch(error => {
            });

        axios.get('languages').then(response => {
            const languages = response?.data as Language[];
            setLanguages(languages || []);
        })
            .catch(error => {
            });

        fetchFormSetting();
    }, []);

    useEffect(() => {
        if (formSetting) {
            componentDidMount();
        }
    }, [formSetting])

    const componentDidMount = () => {
        if (formSetting?.useCaptcha) {
            loadCaptchaEnginge(6);
        }
    };

    const validate = (): boolean => {
        if (!formSetting?.useCaptcha) {
            return true;
        }
        const isCaptchaValid = validateCaptcha(captchaValue);
        if (!isCaptchaValid) {
            setCaptchaValue('');
        }

        return isCaptchaValid;
    }

    const handleCaptchaValueChange = (value: string) => {
        setCaptchaValue(value);
    }


    const displaySuccessToasts = () => {
        let showAdminAcceptance = formSetting?.adminNeedToAcceptUser;
        let showEmailAcceptance = formSetting?.confirmEmail;

        if (showAdminAcceptance && showEmailAcceptance) {
            toast.success(t('register.emailSentAndConfirmationNeed'));
            return;
        }

        if (showAdminAcceptance) {
            toast.success(t('register.registrationConfirmationNeed'));
            return;
        }
        if (showEmailAcceptance) {
            toast.success(t('register.registrationEmailSent'));
            return;
        }

        if (!showAdminAcceptance && !showEmailAcceptance) {
            toast.success(t('register.registeredSuccessfully'));
            return;
        }
    }

    const prepareRegisterDto = (values: RegisterFormValues): RegisterDto => {
        const registerDto: RegisterDto = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            redirectUrl: process.env.REACT_APP_WEB_SHOP_LOGIN_PAGE as string,
            language: values.language,
            companyName: values.companyName,
            accountType: COMPANY_ACCOUNT_TYPE,
            emailHasToBeConfirmed: formSetting?.confirmEmail,
            hasToBeConfirmedByAdmin: formSetting?.adminNeedToAcceptUser,
            address: {
                addressType: "MAIN",
                country: values.country,
                postalCode: values.postalCode,
                city: values.city,
                streetName: values.streetName,
                buildingNumber: values.buildingNumber,
                flatNumber: values.flatNumber,
                region: "",
                addressComments: ""
            },
            contact: {
                contactType: "REPRESENTATIVE",
                title: values.title,
                phone: values.phone,
                mobile: "",
                website: "",
                contactComments: ""
            }
        }
        return registerDto;
    }

    const validationSchema = yup.object().shape({
        firstName: yup.string().required("validation.firstNameRequired"),
        lastName: yup.string().required("validation.lastNameRequired"),
        password: yup.string().min(6, ("validation.passwordTooShort"))
            .required("validation.passwordRequired"),
        passwordConfirmation: yup.string()
            .oneOf([yup.ref('password')], 'validation.passwordConfirmation')
            .required("validation.passwordConfirmationRequired"),
        email: yup.string().email("validation.invalidEmail")
            .required("validation.emailRequired"),
        phone: yup.string().required("validation.phoneRequired"),
        country: yup.string().required("validation.countryRequired"),
        companyName: yup.string().required("validation.companyNameRequired"),
        postalCode: yup.string().required("validation.postalCodeRequired"),
        city: yup.string().required("validation.cityRequired"),
        streetName: yup.string().required("validation.streetNameRequired"),
        buildingNumber: yup.string().required("validation.buildingNumberRequired"),
    });

    const handleAcceptTermsChange = () => {
        setCheckIfTermsAccepted(!checkIfTermsAccepted);
    };

    const displayFillRequiredData = (errors: FormikErrors<RegisterFormValues>) => {
        if (Object.keys(errors).length > 0) {
            toast.error(t('register.fillAllRequiredData'));
        }
        if (!checkIfTermsAccepted) {
            toast.error(t('register.acceptRegulations'));
        }
    }


    const register = (values: RegisterFormValues): void | Promise<any> => {
        setHasFormBeenSubmited(true);
        if (formValid) {
            axios.post('auth/register', prepareRegisterDto(values)).then((response) => {
                if (response) {
                    displaySuccessToasts();
                    navigate("/");
                } else {
                    showError("Error during registration")
                }
            });
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.menu}>
                <LoginMenu />
            </div>
            <Formik validateOnMount initialValues={{
                firstName: "", lastName: "", email: "", password: "", passwordConfirmation: "", addressType: "", country: "", postalCode: "",
                city: "", streetName: "", buildingNumber: "", flatNumber: "", region: "", addressComments: "", title: "",
                phone: "", mobile: "", website: "", contactComments: "", language: "", companyName: "", accountType: ""
            }} validationSchema={validationSchema}
                onSubmit={function (values: RegisterFormValues): void | Promise<any> {
                    if (validate() && checkIfTermsAccepted) {
                        axios.post('auth/register', prepareRegisterDto(values)).then((response) => {
                            if (response) {
                                displaySuccessToasts();
                                navigate("/");
                            } else {
                                showError(t("Registration error"))
                            }
                        });
                    }
                    else {
                        showError(t("register.captchaError"))
                    }
                }}
                validate={(values: RegisterFormValues) => {
                    const errors: any = {};
                    if (
                        !values.firstName
                        || !values.lastName || !values.email || !values.companyName
                        || !values.phone || !values.country || !values.postalCode || !values.city || !values.streetName
                        || !values.buildingNumber
                    ) {
                        setFormValid(false);

                    } else {
                        setFormValid(true);
                    }
                    return errors;
                }}
            >{({
                values, handleChange, errors, touched }) =>
                <Form className={styles.form} onMouseEnter={() => { }} onSubmit={(e) => {
                    e.preventDefault();
                    register(values);
                }}>
                    <div className={styles.center_section}>
                        <div className={styles.basic_data}>
                            <div className={styles.section_title}>{t('register.basicData')}</div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.firstName')}*</p>
                                <input className={`${styles.normal_field} ${hasFormBeenSubmited && errors.firstName ? styles.error_border : ''}`} name="firstName" value={values.firstName} onChange={handleChange}></input>
                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.lastName')}*</p>
                                <input className={`${styles.normal_field} ${hasFormBeenSubmited && errors.lastName ? styles.error_border : ''}`} name="lastName" value={values.lastName} onChange={handleChange}></input>
                            </div>

                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.email')}*</p>
                                <input className={`${styles.normal_field} ${hasFormBeenSubmited && errors.email ? styles.error_border : ''}`} name="email" value={values.email} onChange={handleChange}></input>
                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.phone')}*</p>
                                <input className={`${styles.normal_field} ${hasFormBeenSubmited && errors.phone ? styles.error_border : ''}`} name="phone" value={values.phone} onChange={handleChange}></input>
                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.password')}*</p>
                                <input className={`${styles.normal_field} ${hasFormBeenSubmited && errors.password ? styles.error_border : ''}`} name="password" type={"password"} value={values.password} onChange={handleChange}></input>
                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.passwordConfirmation')}*</p>
                                <input className={`${styles.normal_field} ${hasFormBeenSubmited && errors.passwordConfirmation ? styles.error_border : ''}`} name="passwordConfirmation" type="password" value={values.passwordConfirmation} onChange={handleChange}></input>
                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.language')}</p>
                                <select name="language" className={styles.language_dropdown} value={values.language} onChange={handleChange}>
                                    <option disabled></option>
                                    {languages.map((language: any, index: number) => (
                                        <option key={index} value={language?.id}>{language?.name}</option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <div className={styles.address_data}>
                            <div className={styles.section_title}>{t('register.companyData')}</div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.companyName')}*</p>
                                <input className={`${styles.normal_field} ${hasFormBeenSubmited && errors.companyName ? styles.error_border : ''}`} name="companyName" value={values.companyName} onChange={handleChange}></input>
                            </div>
                            <div className={`${styles.form_row} ${styles.special_form_row}`}>
                                <div className={styles.field_name}>
                                    {t('register.streetName')}*
                                    <br />
                                    <span className={styles.small_text}>{t('register.buildingNumber/localNumber')}</span>
                                </div>
                                <div className={styles.inputs_container}>
                                    <input className={`${styles.normal_field} ${styles.custom_field}  ${hasFormBeenSubmited && errors.streetName ? styles.error_border : ''}`} name="streetName" value={values.streetName} onChange={handleChange}></input>
                                    <div className={styles.small_inputs_container}>
                                        <input className={styles.small_field} name="buildingNumber" value={values.buildingNumber} onChange={handleChange}></input>
                                        <div className={styles.divider}>
                                            /
                                        </div>
                                        <input className={styles.small_field} name="flatNumber" value={values.flatNumber} onChange={handleChange}></input>
                                    </div>
                                </div>
                            </div>
                            <div>

                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.postalCode')}*</p>
                                <input className={`${styles.normal_field}  ${hasFormBeenSubmited && errors.postalCode ? styles.error_border : ''}`} name="postalCode" value={values.postalCode} onChange={handleChange} ></input>
                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.city')}*</p>
                                <input className={`${styles.normal_field}  ${hasFormBeenSubmited && errors.city ? styles.error_border : ''}`} name="city" value={values.city} onChange={handleChange}></input>
                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.country')}*</p>
                                <select name="country" className={`${styles.country_dropdown} ${hasFormBeenSubmited && errors.country ? styles.error_border : ''}`} value={values.country} onChange={handleChange}>
                                    <option disabled></option>
                                    {countries.map((country: any, index: number) => (
                                        <option key={index} value={country?.id}>{country?.name}</option>
                                    ))}
                                </select>
                            </div>
                            {
                                formSetting?.useCaptcha && (
                                    <div className={styles.form_row}>
                                        <LoadCanvasTemplate reloadColor="red" />
                                        <input
                                            className={styles.normal_field}
                                            placeholder={t("register.enterCaptcha") || undefined}
                                            value={captchaValue}
                                            onChange={(event) => handleCaptchaValueChange(event.target.value)}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <div className={styles.checkbox_container}>
                            <div className={styles.section_title}>{t('register.requiredAcceptanceAndConfirmations')} </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.periodicalEmail')}</p>
                                <input className={styles.normal_field} type="checkbox" id="myCheckbox" name="myCheckbox" />
                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t('register.termsAcceptation')}*</p>
                                <input className={styles.normal_field} type="checkbox" id="acceptTermsAndConditions" name="acceptTermsAndConditions" onChange={handleAcceptTermsChange}
                                    checked={checkIfTermsAccepted} />
                            </div>
                        </div >
                        <div className={styles.button_container}>
                            <button type='submit' onClick={() => {
                                displayFillRequiredData(errors);
                            }} >{t("register.submit")}</button>
                        </div>
                    </div >
                </Form >
                }
            </Formik >
            <div className={styles.basic_information}>
                <BasicInformation />
            </div>
        </div>

    );
};

export default RegisterCompanyForm;