import FixcenterFoooterBanner from "../../../components/fixcenter-footer-banner/FixcenterFooterBanner";
import FixcenterSupport from "../../../components/fixcenter-support/FixcenterSupport";
import ProductsCarousel from "../../../components/products-page/components/products-carousel/ProductsCarousel";
import { Category } from "../../../components/products-page/components/products-categories/models/Category.model";
import { ProductImage } from "../../../shared/product-card/models/product-image.model";
import { decodeImageService, getProductImagesService } from "../../../shared/services";
import styles from "./FixcenterProductsPage.module.scss"
import { useEffect, useState } from 'react';

export default function ProductsPage(props: any) {
    const [selectedImage, setSelectedImage] = useState<string>('');
    const [imageAnimation, setImageAnimation] = useState(false);
    const [firstProduct, setFirstProduct] = useState<Category>();

    const handleSetFirstProduct = (product: Category) => {
        setFirstProduct(product);
    }

    useEffect(() => {
        if (firstProduct) {
            getProductImagesService(firstProduct.id).then((response) => {
                const image = response.data as ProductImage[];
                setSelectedImage(decodeImageService(image[0]?.content, image[0]?.name) as string);
            })
        }
    }, [firstProduct])

    const handleImageSelect = (src: string): void => {
        setImageAnimation(true);
        setSelectedImage(src);

        setTimeout(() => {
            setImageAnimation(false);
        }, 250);
    };

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.row}>
                <div className={styles.title}>
                    <div>
                        <p className={styles.title_text_0}>WELCOME TO</p>
                        <p className={styles.title_text_1}> FIXCENTER</p>

                        <p className={styles.title_text_2}>We support you</p>
                        <button>view more</button>
                    </div>
                </div>
                <div className={styles.product_zoom}>
                    {selectedImage &&
                        <div className={imageAnimation ? styles.fade_disable : styles.fade_active}>
                            <img src={selectedImage} />
                        </div>
                    }
                </div>
                <div className={styles.carousel}>
                    <ProductsCarousel onImageSelect={handleImageSelect} handleSetFirstProduct={handleSetFirstProduct} />
                </div>
            </div>
            <div className={styles.row}>
                <FixcenterSupport variant={1} />
            </div>
            <div className={styles.row}>
                <FixcenterFoooterBanner />
            </div>
        </div>
    );

}
