import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './FixcenterFooter.module.scss';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { getWebshopFooter } from '../../../containers/cms-container/services';
import { Category } from '../../../components/products-page/components/products-categories/models/Category.model';
import axios from 'axios';
import * as authService from '../../../auth/services/auth.service';

const getFooter = (languageCode: string): any => getWebshopFooter(languageCode);

export default function FixcenterFooter() {

    const [mainCategories, setMainCategories] = useState<Category[]>([]);
    const { t } = useTranslation('translation');
    const [footer, setFooter] = useState<string>();
    const getLanguageCode = (): string | null => localStorage.getItem("lang");
    const guidEmpty = "00000000-0000-0000-0000-000000000000";

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`categories/tree/${guidEmpty}`);
            const categories: Category[] = response?.data;
            setMainCategories(categories);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSetFooter = () => {
        const languageCode = getLanguageCode();
        if (languageCode) {
            getFooter(languageCode).then((response: any) => {
                setFooter(response.data);
            })
        }
    }

    useEffect(() => {
        fetchCategories();
        handleSetFooter();
    }, [])

    const renderSections = () => {
        if (!footer) return null;

        const sections = footer.split('<h3>');

        return sections.map((section, index) => {
            if (index === 0) return null;
            const [title, content] = section.split('</h3>');
            return (
                <div key={index} className={styles.footer_section}>
                    <h3>{title}</h3>
                    {parse(content)}
                </div>
            );
        });
    };

    return (
        <><div className={styles.container}>
            <div className={styles.footer_container}>
                <div className={styles.columns}>
                    <div className={styles.footer_section}>
                        <h3>{t("fixcenterFooter.categories")}</h3>
                        <ul>
                            {mainCategories.map((category, index) => (
                                <li key={index}>
                                    <a href={authService.checkLogInStatus() === true ? `/webshop/category/${category.name}/${category.id}` : '/login'}>{category.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {renderSections()}</div>
            </div>
        </div></>
    );
}
